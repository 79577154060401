* {
    color: white;
    font-family: 'hp_simplifiedlight';
}

@font-face {
    font-family: 'hp_simplifiedlight';
    src: url('../hp-simplified-light-153-webfont.woff2') format('woff2'),
         url('../hp-simplified-light-153-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.Login{
    background-color: #1F1F1F;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 3vw;
    font-weight: normal;
    padding-bottom: 3vh;
}

.authLogo {
    width: 15%;
    height: auto;
    margin: 4vw;
}

.Inputs {
    width: 12%;
    min-width: 200px;
}

.Inputs h1 {
    font-weight: normal;
    font-size: 22px;
}

.Inputs input{
    width: 100%;
    margin: 8px 0 0 0;
    padding: 5px;

    background-color: black;
    border: none;
    border-radius: 5px;

    font-size: 18px;
}

.Inputs input[type="checkbox"] {
    display: inline;
    width: auto;
    margin-bottom: 10px;
}

.Inputs label {
    padding: 0 0 0 10px;
}

.Inputs button {
    width: 100%;
    padding: 5px;
    box-sizing: content-box;

    background-color: black;
    border: None;
    border-radius: 5px;

    font-size: 18px;
}

.Inputs button:hover {
    box-shadow: 0 0 7px white;
}

.registerLink {
    text-decoration: none;
    display: block;
    margin-top:10px;
}

.registerLink:hover {
    text-decoration: underline;
}
