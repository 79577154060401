* {
    margin: 0;
}

@font-face {
    font-family: 'hp_simplifiedlight';
    src: url('../hp-simplified-light-153-webfont.woff2') format('woff2'),
         url('../hp-simplified-light-153-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.Main {
    display: flex;
    height: 100vh;

    color: white;
    font-family: "hp_simplifiedlight";
}

.sidebar {
    width: 15%;
    min-width: 65px;

    background-color: #111;
    
    padding: 20px 0px 0px 0px;
    text-align: center;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.main {
    width: 85%; 
    background-color: #1F1F1F;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
}

.time {
    font-size: 6vw;
    justify-self: center;
}

.averages {
    font-size: 3vw;
    text-align: center;
    margin-bottom: 30px;
}

.Scramble {
    padding-top: 5vh;
    justify-self: flex-start;
    font-size: 2.5vw;
    word-spacing: 10px;
}

.EntryList{
    margin: 3px 0px 0px 0px;
    padding-top: 5px;
    height: 60%;
}

.EntryList ol::-webkit-scrollbar { 
    display: none;
}

.EntryList ol {
    padding: 10px 0 0 0;
    margin: 0;
    height: 90%;
    
    background-color: #111;

    overflow-y: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}

.EntryList p {
    font-size: 1.5vw;
    margin-bottom: 1.5vh;
}

.EntryList li {
    padding: 0 10px 0 10px;
    margin: 3px;
    font-size: 1.5vw;
    font-family: "Cascadia Code Light";
    text-align: center; 
}

.EntryList li:hover{
    border-radius: 5px;
    -webkit-box-shadow:inset 0px 0px 0px 0.5px grey;
    -moz-box-shadow:inset 0px 0px 0px 0.5px grey;
    box-shadow:inset 0px 0px 0px 0.5px grey;
}

img {
    /*padding: 20px 0 20px 0;*/
    width: 50%;
    height: auto;
}

#title {
    /*margin-top: 3px; */
    font-size: 2.5vw;
    width: 80%;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #FFF;
}

.options {
    flex-direction: row;
    margin-bottom: 20px;
    justify-self: flex-end;
}

.options button{
    font-size: 1vw;
    background-color: #111;
    border: none;
    box-sizing: content-box;
    outline: none;
    margin: 10px;
}

.options button:hover{
    box-shadow: 0 0 7px white;
}

.Modal {
    position: absolute;
    width: 30%;
    height: 30%;
    top: 35%;
    left: 35%;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

.Modal button {
    background-color: black;
    border: none;
    box-sizing: content-box;
    outline: none;
    float: right;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
}

